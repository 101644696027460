/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrderDto } from '../models';
// @ts-ignore
import { PlaceOrderDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
// @ts-ignore
import { ShoppingCartDto } from '../models';
// @ts-ignore
import { ShoppingCartPageDto } from '../models';
// @ts-ignore
import { ShoppingCartSearchResultPageDto } from '../models';
/**
 * ShoppingCartsApi - axios parameter creator
 * @export
 */
export const ShoppingCartsApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a product to your personal shopping cart
         * @param {number} amount 
         * @param {string} productId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductToMyShoppingCart: async (amount: number, productId: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling addProductToMyShoppingCart.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling addProductToMyShoppingCart.');
            }
            const localVarPath = `/shoppingcarts/mine/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShoppingCartDto} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShoppingCart: async (shoppingCart: ShoppingCartDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shoppingCart' is not null or undefined
            if (shoppingCart === null || shoppingCart === undefined) {
                throw new RequiredError('shoppingCart','Required parameter shoppingCart was null or undefined when calling createShoppingCart.');
            }
            const localVarPath = `/shoppingcarts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shoppingCart !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shoppingCart !== undefined ? shoppingCart : {})
                : (shoppingCart || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyShoppingCarts: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyShoppingCarts.');
            }
            const localVarPath = `/shoppingcarts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShoppingCart: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteShoppingCart.');
            }
            const localVarPath = `/shoppingcarts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets your personal shopping cart
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyShoppingCart: async (translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shoppingcarts/mine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCart: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getShoppingCart.');
            }
            const localVarPath = `/shoppingcarts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCarts: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shoppingcarts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCartsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shoppingcarts/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShoppingCart: async (id: string, shoppingCart: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchShoppingCart.');
            }
            // verify required parameter 'shoppingCart' is not null or undefined
            if (shoppingCart === null || shoppingCart === undefined) {
                throw new RequiredError('shoppingCart','Required parameter shoppingCart was null or undefined when calling patchShoppingCart.');
            }
            const localVarPath = `/shoppingcarts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shoppingCart !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shoppingCart !== undefined ? shoppingCart : {})
                : (shoppingCart || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performShoppingCartSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performShoppingCartSearch.');
            }
            const localVarPath = `/shoppingcarts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Converts the current shopping cart into orders. If the shopping cart consists of products from different suppliers, more than one order will be returned
         * @param {PlaceOrderDto} placeOrder 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeOrder: async (placeOrder: PlaceOrderDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeOrder' is not null or undefined
            if (placeOrder === null || placeOrder === undefined) {
                throw new RequiredError('placeOrder','Required parameter placeOrder was null or undefined when calling placeOrder.');
            }
            const localVarPath = `/shoppingcarts/mine/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof placeOrder !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(placeOrder !== undefined ? placeOrder : {})
                : (placeOrder || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ShoppingCartDto} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShoppingCart: async (id: string, shoppingCart: ShoppingCartDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putShoppingCart.');
            }
            // verify required parameter 'shoppingCart' is not null or undefined
            if (shoppingCart === null || shoppingCart === undefined) {
                throw new RequiredError('shoppingCart','Required parameter shoppingCart was null or undefined when calling putShoppingCart.');
            }
            const localVarPath = `/shoppingcarts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof shoppingCart !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(shoppingCart !== undefined ? shoppingCart : {})
                : (shoppingCart || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes positions from your personal shopping cart
         * @param {number} position 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePositionFromMyShoppingCart: async (position: number, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'position' is not null or undefined
            if (position === null || position === undefined) {
                throw new RequiredError('position','Required parameter position was null or undefined when calling removePositionFromMyShoppingCart.');
            }
            const localVarPath = `/shoppingcarts/mine/removeposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (position !== undefined) {
                localVarQueryParameter['position'] = position;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a product from your personal shopping cart
         * @param {number} amount 
         * @param {string} productId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductFromMyShoppingCart: async (amount: number, productId: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling removeProductFromMyShoppingCart.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling removeProductFromMyShoppingCart.');
            }
            const localVarPath = `/shoppingcarts/mine/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a ShoppingCart exists.
         * @summary Check if ShoppingCart exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCartExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shoppingCartExists.');
            }
            const localVarPath = `/shoppingcarts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShoppingCartsApi - functional programming interface
 * @export
 */
export const ShoppingCartsApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a product to your personal shopping cart
         * @param {number} amount 
         * @param {string} productId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProductToMyShoppingCart(amount: number, productId: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).addProductToMyShoppingCart(amount, productId, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ShoppingCartDto} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShoppingCart(shoppingCart: ShoppingCartDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).createShoppingCart(shoppingCart, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyShoppingCarts(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).deleteManyShoppingCarts(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShoppingCart(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).deleteShoppingCart(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets your personal shopping cart
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyShoppingCart(translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).getMyShoppingCart(translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingCart(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).getShoppingCart(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingCarts(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartPageDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).getShoppingCarts(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingCartsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).getShoppingCartsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchShoppingCart(id: string, shoppingCart: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).patchShoppingCart(id, shoppingCart, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performShoppingCartSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartSearchResultPageDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).performShoppingCartSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Converts the current shopping cart into orders. If the shopping cart consists of products from different suppliers, more than one order will be returned
         * @param {PlaceOrderDto} placeOrder 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeOrder(placeOrder: PlaceOrderDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).placeOrder(placeOrder, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ShoppingCartDto} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putShoppingCart(id: string, shoppingCart: ShoppingCartDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).putShoppingCart(id, shoppingCart, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Removes positions from your personal shopping cart
         * @param {number} position 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePositionFromMyShoppingCart(position: number, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).removePositionFromMyShoppingCart(position, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Removes a product from your personal shopping cart
         * @param {number} amount 
         * @param {string} productId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProductFromMyShoppingCart(amount: number, productId: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDto>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).removeProductFromMyShoppingCart(amount, productId, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a ShoppingCart exists.
         * @summary Check if ShoppingCart exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingCartExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShoppingCartsApiAxiosParamCreator(axiosClientConfiguration).shoppingCartExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ShoppingCartsApi - factory interface
 * @export
 */
export const ShoppingCartsApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Adds a product to your personal shopping cart
         * @param {number} amount 
         * @param {string} productId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductToMyShoppingCart(amount: number, productId: string, translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).addProductToMyShoppingCart(amount, productId, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShoppingCartDto} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShoppingCart(shoppingCart: ShoppingCartDto, translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).createShoppingCart(shoppingCart, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyShoppingCarts(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return ShoppingCartsApiFp(axiosClientConfiguration).deleteManyShoppingCarts(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShoppingCart(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return ShoppingCartsApiFp(axiosClientConfiguration).deleteShoppingCart(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets your personal shopping cart
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyShoppingCart(translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).getMyShoppingCart(translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCart(id: string, translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).getShoppingCart(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCarts(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<ShoppingCartPageDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).getShoppingCarts(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCartsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return ShoppingCartsApiFp(axiosClientConfiguration).getShoppingCartsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShoppingCart(id: string, shoppingCart: any, translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).patchShoppingCart(id, shoppingCart, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performShoppingCartSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<ShoppingCartSearchResultPageDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).performShoppingCartSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * Converts the current shopping cart into orders. If the shopping cart consists of products from different suppliers, more than one order will be returned
         * @param {PlaceOrderDto} placeOrder 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeOrder(placeOrder: PlaceOrderDto, translate?: string, options?: any): AxiosPromise<Array<OrderDto>> {
            return ShoppingCartsApiFp(axiosClientConfiguration).placeOrder(placeOrder, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ShoppingCartDto} shoppingCart 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShoppingCart(id: string, shoppingCart: ShoppingCartDto, translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).putShoppingCart(id, shoppingCart, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes positions from your personal shopping cart
         * @param {number} position 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePositionFromMyShoppingCart(position: number, translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).removePositionFromMyShoppingCart(position, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a product from your personal shopping cart
         * @param {number} amount 
         * @param {string} productId 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductFromMyShoppingCart(amount: number, productId: string, translate?: string, options?: any): AxiosPromise<ShoppingCartDto> {
            return ShoppingCartsApiFp(axiosClientConfiguration).removeProductFromMyShoppingCart(amount, productId, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a ShoppingCart exists.
         * @summary Check if ShoppingCart exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCartExists(id: string, options?: any): AxiosPromise<void> {
            return ShoppingCartsApiFp(axiosClientConfiguration).shoppingCartExists(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addProductToMyShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiAddProductToMyShoppingCartRequest
 */
export interface ShoppingCartsApiAddProductToMyShoppingCartRequest {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartsApiAddProductToMyShoppingCart
     */
    readonly amount: number

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiAddProductToMyShoppingCart
     */
    readonly productId: string

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiAddProductToMyShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for createShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiCreateShoppingCartRequest
 */
export interface ShoppingCartsApiCreateShoppingCartRequest {
    /**
     * 
     * @type {ShoppingCartDto}
     * @memberof ShoppingCartsApiCreateShoppingCart
     */
    readonly shoppingCart: ShoppingCartDto

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiCreateShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyShoppingCarts operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiDeleteManyShoppingCartsRequest
 */
export interface ShoppingCartsApiDeleteManyShoppingCartsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShoppingCartsApiDeleteManyShoppingCarts
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiDeleteManyShoppingCarts
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiDeleteShoppingCartRequest
 */
export interface ShoppingCartsApiDeleteShoppingCartRequest {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiDeleteShoppingCart
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiDeleteShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for getMyShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiGetMyShoppingCartRequest
 */
export interface ShoppingCartsApiGetMyShoppingCartRequest {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetMyShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for getShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiGetShoppingCartRequest
 */
export interface ShoppingCartsApiGetShoppingCartRequest {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCart
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for getShoppingCarts operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiGetShoppingCartsRequest
 */
export interface ShoppingCartsApiGetShoppingCartsRequest {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartsApiGetShoppingCarts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ShoppingCartsApiGetShoppingCarts
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCarts
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartsApiGetShoppingCarts
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCarts
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCarts
     */
    readonly search?: string
}

/**
 * Request parameters for getShoppingCartsCount operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiGetShoppingCartsCountRequest
 */
export interface ShoppingCartsApiGetShoppingCartsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartsApiGetShoppingCartsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ShoppingCartsApiGetShoppingCartsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCartsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCartsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartsApiGetShoppingCartsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiGetShoppingCartsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for patchShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiPatchShoppingCartRequest
 */
export interface ShoppingCartsApiPatchShoppingCartRequest {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiPatchShoppingCart
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof ShoppingCartsApiPatchShoppingCart
     */
    readonly shoppingCart: any

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiPatchShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for performShoppingCartSearch operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiPerformShoppingCartSearchRequest
 */
export interface ShoppingCartsApiPerformShoppingCartSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof ShoppingCartsApiPerformShoppingCartSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiPerformShoppingCartSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for placeOrder operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiPlaceOrderRequest
 */
export interface ShoppingCartsApiPlaceOrderRequest {
    /**
     * 
     * @type {PlaceOrderDto}
     * @memberof ShoppingCartsApiPlaceOrder
     */
    readonly placeOrder: PlaceOrderDto

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiPlaceOrder
     */
    readonly translate?: string
}

/**
 * Request parameters for putShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiPutShoppingCartRequest
 */
export interface ShoppingCartsApiPutShoppingCartRequest {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiPutShoppingCart
     */
    readonly id: string

    /**
     * 
     * @type {ShoppingCartDto}
     * @memberof ShoppingCartsApiPutShoppingCart
     */
    readonly shoppingCart: ShoppingCartDto

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiPutShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for removePositionFromMyShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiRemovePositionFromMyShoppingCartRequest
 */
export interface ShoppingCartsApiRemovePositionFromMyShoppingCartRequest {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartsApiRemovePositionFromMyShoppingCart
     */
    readonly position: number

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiRemovePositionFromMyShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for removeProductFromMyShoppingCart operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiRemoveProductFromMyShoppingCartRequest
 */
export interface ShoppingCartsApiRemoveProductFromMyShoppingCartRequest {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartsApiRemoveProductFromMyShoppingCart
     */
    readonly amount: number

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiRemoveProductFromMyShoppingCart
     */
    readonly productId: string

    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiRemoveProductFromMyShoppingCart
     */
    readonly translate?: string
}

/**
 * Request parameters for shoppingCartExists operation in ShoppingCartsApi.
 * @export
 * @interface ShoppingCartsApiShoppingCartExistsRequest
 */
export interface ShoppingCartsApiShoppingCartExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartsApiShoppingCartExists
     */
    readonly id: string
}

/**
 * ShoppingCartsApi - object-oriented interface
 * @export
 * @class ShoppingCartsApi
 * @extends {BaseAPI}
 */
export class ShoppingCartsApi extends BaseAPI {
    /**
     * 
     * @summary Adds a product to your personal shopping cart
     * @param {ShoppingCartsApiAddProductToMyShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public addProductToMyShoppingCart(requestParameters: ShoppingCartsApiAddProductToMyShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).addProductToMyShoppingCart(requestParameters.amount, requestParameters.productId, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiCreateShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public createShoppingCart(requestParameters: ShoppingCartsApiCreateShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).createShoppingCart(requestParameters.shoppingCart, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiDeleteManyShoppingCartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public deleteManyShoppingCarts(requestParameters: ShoppingCartsApiDeleteManyShoppingCartsRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).deleteManyShoppingCarts(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiDeleteShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public deleteShoppingCart(requestParameters: ShoppingCartsApiDeleteShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).deleteShoppingCart(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets your personal shopping cart
     * @param {ShoppingCartsApiGetMyShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public getMyShoppingCart(requestParameters: ShoppingCartsApiGetMyShoppingCartRequest = {}, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).getMyShoppingCart(requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiGetShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public getShoppingCart(requestParameters: ShoppingCartsApiGetShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).getShoppingCart(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiGetShoppingCartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public getShoppingCarts(requestParameters: ShoppingCartsApiGetShoppingCartsRequest = {}, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).getShoppingCarts(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiGetShoppingCartsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public getShoppingCartsCount(requestParameters: ShoppingCartsApiGetShoppingCartsCountRequest = {}, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).getShoppingCartsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiPatchShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public patchShoppingCart(requestParameters: ShoppingCartsApiPatchShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).patchShoppingCart(requestParameters.id, requestParameters.shoppingCart, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiPerformShoppingCartSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public performShoppingCartSearch(requestParameters: ShoppingCartsApiPerformShoppingCartSearchRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).performShoppingCartSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Converts the current shopping cart into orders. If the shopping cart consists of products from different suppliers, more than one order will be returned
     * @param {ShoppingCartsApiPlaceOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public placeOrder(requestParameters: ShoppingCartsApiPlaceOrderRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).placeOrder(requestParameters.placeOrder, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShoppingCartsApiPutShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public putShoppingCart(requestParameters: ShoppingCartsApiPutShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).putShoppingCart(requestParameters.id, requestParameters.shoppingCart, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes positions from your personal shopping cart
     * @param {ShoppingCartsApiRemovePositionFromMyShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public removePositionFromMyShoppingCart(requestParameters: ShoppingCartsApiRemovePositionFromMyShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).removePositionFromMyShoppingCart(requestParameters.position, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a product from your personal shopping cart
     * @param {ShoppingCartsApiRemoveProductFromMyShoppingCartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public removeProductFromMyShoppingCart(requestParameters: ShoppingCartsApiRemoveProductFromMyShoppingCartRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).removeProductFromMyShoppingCart(requestParameters.amount, requestParameters.productId, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a ShoppingCart exists.
     * @summary Check if ShoppingCart exists
     * @param {ShoppingCartsApiShoppingCartExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public shoppingCartExists(requestParameters: ShoppingCartsApiShoppingCartExistsRequest, options?: any) {
        return ShoppingCartsApiFp(this.axiosClientConfiguration).shoppingCartExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
