@if (currentUserId$ | async) {
  <smallstack-store-container [store]="shoppingCartStore">
    @if (shoppingCartStore.value$ | async; as shoppingCart) {
      @if (shoppingCart.orderPositions?.length > 0) {
        <table style="border: 0px; width: 100%">
          @for (
            orderPosition of shoppingCart?.orderPositions;
            track trackByModel(index, orderPosition);
            let index = $index
          ) {
            <tr>
              <td style="width: 80px; vertical-align: top">
                <smallstack-store-model [modelId]="orderPosition.productId" [store]="productsStore">
                  <ng-template let-model="model">
                    @if (model.images[0]?.fileUrl) {
                      <div class="cart-image" style="background-image: url({{ model.images[0].fileUrl }});"></div>
                    }
                  </ng-template>
                </smallstack-store-model>
              </td>
              <td style="vertical-align: top">
                <smallstack-store-model [modelId]="orderPosition.productId" [store]="productsStore">
                  <ng-template let-model="model">
                    <b> <smallstack-i18n [data]="model.title" /> </b><br />
                    <smallstack-i18n [data]="model.shortDescription" /><br />
                  </ng-template>
                </smallstack-store-model>
                <div
                  style="padding-top: 10px; padding-bottom: 10px; width: 20px"
                  class="flex flex-row space-x-5 justify-between items-center"
                >
                  <button class="btn btn-outline btn-primary" [loadingFn]="removeProduct(orderPosition.productId)">
                    <smallstack-icon alias="minus" color="cccccc" />
                  </button>
                  <div style="font-size: 16px">{{ orderPosition.amount }}</div>
                  <button class="btn btn-outline btn-primary" [loadingFn]="addProduct(orderPosition.productId)">
                    <smallstack-icon alias="plus" color="cccccc" />
                  </button>
                  <button class="btn btn-outline btn-warning" [loadingFn]="removePosition(index)">
                    <smallstack-icon alias="delete" color="cccccc" />
                  </button>
                </div>
              </td>
              <td style="text-align: right">
                <smallstack-store-model [modelId]="orderPosition.productId" [store]="productsStore">
                  <ng-template let-product="model">
                    @for (price of product.prices; track trackByIndex($index, price)) {
                      <div>
                        <smallstack-store-model [modelId]="price.priceZoneId" [store]="priceZoneStore">
                          <ng-template let-priceZone="model">
                            <smallstack-currency [iso]="priceZone.currency" [value]="price.value" />
                          </ng-template>
                        </smallstack-store-model>
                      </div>
                    }
                  </ng-template> </smallstack-store-model
                ><br />
              </td>
            </tr>
          }
        </table>
        <div style="text-align: end">
          <b>
            <smallstack-i18n data="@@components.cartdetails.total" />
          </b>
          @for (price of shoppingCartStore.totalPrices$ | async; track price) {
            <div>
              <smallstack-currency [iso]="price.priceZone.currency" [value]="price.value" />
            </div>
          }
        </div>
      } @else {
        <div style="text-align: center; padding: 20px">
          <smallstack-i18n data="@@components.cartdetails.noitems" />
        </div>
      }
    }
  </smallstack-store-container>
  @if (data()?.orderLink) {
    <div class="order-now-box">
      <button class="order-now-btn btn btn-primary" (click)="orderNow()">
        <smallstack-i18n data="@@components.cartdetails.ordernow" />
      </button>
    </div>
  }
} @else {
  <div style="text-align: center; padding: 20px">
    <smallstack-i18n data="@@components.cartdetails.pleaselogin" />
  </div>
}
