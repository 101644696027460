import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { ArithmeticOperand } from "@smallstack/legacy-utils";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const MathWidget: WidgetRegistryEntry = {
  name: "Math",
  configuration: {
    templateName: "Kalkulation",
    templateDescription: "Berechnet einen Wert aus einem anderen Wert und zeigt diesen an.",
    dataSchema: {
      properties: {
        valueA: {
          type: "string",
          title: "Wert A",
          description: "Der erste Wert der berechnet werden soll."
        },
        valueB: {
          type: "string",
          title: "Wert B",
          description: "Der zweite Wert der berechnet werden soll."
        },
        operator: {
          type: "string",
          title: "Operator",
          description: "Der Operator der verwendet werden soll.",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_SELECT,
            values: [
              {
                label: "Plus",
                value: ArithmeticOperand.ADD
              },
              {
                label: "Minus",
                value: ArithmeticOperand.SUBTRACT
              },
              {
                label: "Mal",
                value: ArithmeticOperand.MULTIPLY
              },
              {
                label: "Geteilt",
                value: ArithmeticOperand.DIVIDE
              }
            ]
          }
        },
        prefix: {
          type: "string",
          title: "Prefix",
          description: "Ein Prefix der vor dem Wert angezeigt werden soll."
        },
        suffix: {
          type: "string",
          title: "Suffix",
          description: "Ein Suffix der nach dem Wert angezeigt werden soll."
        },
        asCurrency: {
          type: "boolean",
          title: "Als Währung anzeigen",
          description: "Zeigt den Wert als Währung an."
        }
      }
    },
    icon: "math"
  },
  component: () => import("./math-widget.component").then((m) => m.MathWidgetComponent)
};
