import { WidgetRegistry } from "@smallstack/widget-core";
import { AnalyticsOptInWidget } from "./lib/widgets/analytics-opt-in-widget";
import { AuthorizedContainerWidget } from "./lib/widgets/authorized-container-widget";
import { AutoLogoutWidget } from "./lib/widgets/auto-logout-widget";
import { ChangePasswordWidget } from "./lib/widgets/change-password-widget";
import { ContactDetailWidget } from "./lib/widgets/contact-detail-widget";
import { CreateUserWidget } from "./lib/widgets/create-user-widget";
import { HasUnreadSystemMessagesWidget } from "./lib/widgets/has-unread-system-messages-widget";
import { LoginWidget } from "./lib/widgets/login-widget";
import { LogoutButtonWidget } from "./lib/widgets/logout-button-widget";
import { MessengerWidget } from "./lib/widgets/messenger-widget";
import { PasswordLoginWidget } from "./lib/widgets/password-login-widget";
import { PasswordResetWidget } from "./lib/widgets/password-reset-widget";
import { ProfileWidget } from "./lib/widgets/profile-widget";
import { ReloadCurrentUserWidget } from "./lib/widgets/reload-current-user-widget";
import { SystemMessageSenderWidget } from "./lib/widgets/system-message-sender-widget";
import { SystemMessagesContainerWidget } from "./lib/widgets/system-messages-container-widget";
import { ThreadMessengerWidget } from "./lib/widgets/thread-messenger-widget";
import { UnreadMessagesCountWidget } from "./lib/widgets/unread-messages-count-widget";
import { UserListWidget } from "./lib/widgets/user-list-widget";

export function addUserWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(AnalyticsOptInWidget);
  widgetRegistry.addWidget(AuthorizedContainerWidget);
  widgetRegistry.addWidget(ContactDetailWidget);
  widgetRegistry.addWidget(CreateUserWidget);
  widgetRegistry.addWidget(HasUnreadSystemMessagesWidget);
  widgetRegistry.addWidget(LoginWidget);
  widgetRegistry.addWidget(LogoutButtonWidget);
  widgetRegistry.addWidget(MessengerWidget);
  widgetRegistry.addWidget(PasswordLoginWidget);
  widgetRegistry.addWidget(PasswordResetWidget);
  widgetRegistry.addWidget(ProfileWidget);
  widgetRegistry.addWidget(SystemMessagesContainerWidget);
  widgetRegistry.addWidget(UnreadMessagesCountWidget);
  widgetRegistry.addWidget(UserListWidget);
  widgetRegistry.addWidget(ChangePasswordWidget);
  widgetRegistry.addWidget(AutoLogoutWidget);
  widgetRegistry.addWidget(SystemMessageSenderWidget);
  widgetRegistry.addWidget(ReloadCurrentUserWidget);
  widgetRegistry.addWidget(ThreadMessengerWidget);
}
