import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseWidgetComponent } from "@smallstack/widget-core";
import { ProductPricesComponent } from "../../product-prices/product-prices.component";

@Component({
  selector: "smallstack-product-prices-widget",
  templateUrl: "./product-prices-widget.component.html",
  styleUrls: ["./product-prices-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProductPricesComponent]
})
export class ProductPricesWidgetComponent extends BaseWidgetComponent {
  public currentProduct: any;

  constructor() {
    super();
    this.currentProduct = this.context()?.product;
  }
}
