@if (options$ | async; as options) {
  <div
    class="popup-widget-container flex flex-col justify-center items-center"
    [ngClass]="{ error: hasErrors() }"
    (click)="openContactPersonSelect()"
  >
    @if (!selectedContactPerson) {
      <smallstack-icon alias="people" size="64" />
    }
    @if (selectedContactPerson) {
      <div class="flex flex-col justify-center items-center">
        <smallstack-avatar size="xs" [url]="selectedContactPerson.avatarUrl" />
        <span>{{ selectedContactPerson.firstName }} {{ selectedContactPerson.lastName }}</span></div
      >
    }
    @if (options?.showLabel !== false) {
      <div
        ><span>
          <smallstack-i18n [data]="label" />
        </span>
        @if (required) {
          <span>*</span>
        }
      </div>
    }
    <mat-error>
      <smallstack-form-control-error [control]="formControl" />
    </mat-error>
  </div>
  <smallstack-form-control-dummy [control]="formControl" />
}
