import { WidgetRegistry } from "@smallstack/widget-core";
import { BackofficeHeaderConfigurationWidget } from "./backoffice-header-actions-container-widget";
import { BackofficeHelpButtonWidget } from "./backoffice-help-button-widget";
import { BackofficeHelpWidget } from "./backoffice-help-widget";
import { BackofficeNavigationIconWidget } from "./backoffice-navigation-icon-widget";
import { CompleteProfileWidget } from "./complete-profile-widget";
import { CustomerBackofficeWidget } from "./customer-backoffice-widget";
import { EmailVerificationWidget } from "./email-verification-widget";
import { LastCompanyWidget } from "./last-company-widget";
import { LastProjectWidget } from "./last-project-widget";
import { ReceivedInvitationWidget } from "./received-invitation-widget";
import { ThreadMessageTemplatesOverviewWidget } from "./thread-message-templates-overview-widget";

export function registerBackofficeWidgets(widgetRegistry: WidgetRegistry): void {
  widgetRegistry.addWidget(BackofficeHeaderConfigurationWidget);
  widgetRegistry.addWidget(BackofficeNavigationIconWidget);
  widgetRegistry.addWidget(CompleteProfileWidget);
  widgetRegistry.addWidget(CustomerBackofficeWidget);
  widgetRegistry.addWidget(EmailVerificationWidget);
  widgetRegistry.addWidget(LastCompanyWidget);
  widgetRegistry.addWidget(LastProjectWidget);
  widgetRegistry.addWidget(BackofficeHelpWidget);
  widgetRegistry.addWidget(BackofficeHelpButtonWidget);
  widgetRegistry.addWidget(ReceivedInvitationWidget);
  widgetRegistry.addWidget(ThreadMessageTemplatesOverviewWidget);
}
