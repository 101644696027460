@if (!filteredProductStore) {
  <smallstack-loader loadingText="Produkte werden geladen" />
} @else {
  <smallstack-list-container [store]="filteredProductStore" [showPager]="data()?.showPaginator !== 'never'">
    <ng-template #content>
      <div class="grid">
        @for (product of filteredProductStore.currentPage$ | async; track product) {
          <div [ngStyle]="{ cursor: data()?.link ? 'pointer' : 'unset' }" (click)="navigateTo(product)">
            <div style="position: relative; overflow: hidden; padding: 5px" [ngClass]="data()?.className">
              <smallstack-widget-tree
                [widget]="data()?.productListElement"
                [context]="{
                  product: {
                    id: product.id,
                    createdAt: product.createdAt,
                    price: product.prices[0].value,
                    shortTitle: product.shortTitle | translate,
                    shortDescription: product.shortDescription | translate,
                    imageUrl: product.images[0]?.fileUrl
                  }
                }"
              />
            </div>
          </div>
        }
      </div>
    </ng-template>
  </smallstack-list-container>
}
