import { ChangeDetectionStrategy, Component, WritableSignal, effect, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { isFakeEmailAddress } from "@smallstack/legacy-utils";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { UserService } from "../../services/user.service";

interface State {
  type: "verified" | "unverified" | "error" | "verifying" | "nothing" | "loading";
  emailAddress?: string;
}

@Widget({
  name: "EmailVerificationBanner",
  templateName: "E-Mail Verifizierung Banner",
  templateDescription:
    "Zeigt einen roten Banner an, falls der angemeldete Benutzer nicht verifiziert ist. Über einen Klick kann sich der Benutzer erneut verifizieren.",
  icon: "email",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      redirectUrl: {
        type: "string",
        title: "Weiterleitungs-Url",
        description:
          "Diese Url wird in der Verifizierungs-E-Mail verwendet, um den Benutzer zu verifizieren. An diese Url wird lediglich das Token angehängt. Beispiel: https://meineapp.smallstack.com/profile?verificationToken="
      }
    }
  }
})
@Component({
  selector: "smallstack-user-email-verification-banner",
  standalone: true,
  imports: [LoadingElementDirective],
  templateUrl: "./email-verification-banner.component.html",
  styleUrls: ["./email-verification-banner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVerificationBannerComponent extends BaseWidgetComponent {
  protected state: WritableSignal<State> = signal({ type: "loading" });

  constructor(
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService,
    activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    super();

    void activatedRoute.queryParams.subscribe(async (params) => {
      if (params.verificationToken) {
        this.state.set({ type: "verifying" });
        try {
          await this.axiosApiClient.get(UsersApi).confirmEmail({ token: params.verificationToken });
          this.state.set({ type: "verified" });
          await this.userService.reloadUser();
        } catch (e) {
          this.state.set({ type: "error" });
        }
      }
    });
    effect(
      () => {
        const currentUser = this.userService.currentUser();

        if (!currentUser?.user?.primaryEmail?.validated && !isFakeEmailAddress(currentUser?.user?.primaryEmailAddress))
          this.state.set({ type: "unverified", emailAddress: currentUser?.user?.primaryEmail?.address });
      },
      { allowSignalWrites: true }
    );
  }

  public sendVerificationMail() {
    return async (): Promise<void> => {
      const currentUser = this.userService.getCurrentUserSnapshot();
      const redirectUrl =
        this.data()?.redirectUrl || window.location.origin + window.location.pathname + "?verificationToken=";
      await this.axiosApiClient.get(UsersApi).sendEmailVerification({
        emailVerificationDto: { emailAddress: currentUser.primaryEmailAddress, redirectUrl },
        userId: currentUser.id
      });
      this.notificationService.popup.success(
        "E-Mail versendet",
        "Wir haben Ihnen erneut eine Verifizierungsmail an " + currentUser.primaryEmailAddress + " zugesendet."
      );
    };
  }
}
