import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import {
  AxiosApiClient,
  ContactCustomFieldDto,
  ContactCustomFieldDtoTypeEnum,
  ContactCustomFieldsApi,
  ContactDto,
  FileDto
} from "@smallstack/axios-api-client";
import { NonEmptyObjectPipe } from "@smallstack/common-components";
import { FileExplorerButtonComponent, FileStore } from "@smallstack/file-components";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { MetaObject, cloneObject } from "@smallstack/legacy-utils";
import { LoaderComponent } from "@smallstack/store-components";
import { HelpIconComponent } from "@smallstack/text-components";
import { IconComponent } from "@smallstack/theme-components";
import { TYPE_CONTACTS, WIDGET_FORM_INPUT_DATE_STRING, WIDGET_FORM_INPUT_GOOGLE_MAPS } from "@smallstack/typesystem";
import { injectStore } from "@smallstack/typesystem-client";
import { AvatarComponent } from "@smallstack/user-components";
import {
  BaseWidgetComponent,
  FormComponent,
  SchemaFormLayoutCustomComponent,
  SchemaFormPlaceholderDirective,
  SchemaFormTableComponent,
  SocketAwareWidget,
  Widget
} from "@smallstack/widget-core";
import { JSONSchema7TypeName } from "json-schema";
import { combineLatest, from, tap } from "rxjs";

@Widget({
  name: "ContactEditor",
  icon: "contact",
  templateName: "Kontakt Editor",
  templateDescription: "Ein Editor für Kontakte",
  dataSchema: {
    type: "object",
    properties: {
      contactContextVariable: {
        type: "string",
        title: "Kontakt Kontext Variable"
      }
    }
  }
})
@Component({
  selector: "smallstack-contact-editor-widget",
  templateUrl: "./contact-editor-widget.component.html",
  styleUrls: ["./contact-editor-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatBottomSheetModule,
    IconComponent,
    I18nComponent,
    LoaderComponent,
    NonEmptyObjectPipe,
    AvatarComponent,
    HelpIconComponent,
    FileExplorerButtonComponent,
    FormComponent,
    SchemaFormLayoutCustomComponent,
    SchemaFormTableComponent,
    SchemaFormPlaceholderDirective
  ]
})
export class ContactEditorWidgetComponent
  extends BaseWidgetComponent<{ contactContextVariable: string }>
  implements SocketAwareWidget
{
  public readonly SCHEMA: SchemaFormSchema = {
    type: "object",
    properties: {
      title: {
        title: "@@user.title",
        type: "string"
      },
      salutation: {
        title: "@@user.salutation",
        type: "string",
        enum: ["Herr", "Frau"]
      },
      firstName: {
        title: "@@components.crm.contacts.firstname",
        type: "string",
        maxLength: 128
      },
      lastName: {
        title: "@@components.crm.contacts.lastname",
        type: "string",
        maxLength: 128
      },
      birthday: {
        title: "@@components.crm.contacts.birthday",
        type: "string",
        maxLength: 10,
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_DATE_STRING }
      },
      address: {
        title: "@@components.crm.contacts.address",
        type: "object",
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_GOOGLE_MAPS, asModal: true }
      },
      email: {
        title: "@@components.crm.contacts.email",
        type: "string"
      },
      phone: {
        title: "@@components.crm.contacts.phone",
        type: "string"
      },
      mobile: {
        title: "@@components.crm.contacts.mobile",
        type: "string"
      },
      company: {
        type: "string",
        title: "@@components.crm.contacts.company"
      },
      companyPosition: {
        type: "string",
        title: "@@components.crm.contacts.companyPosition"
      },
      companyPhone: {
        type: "string",
        title: "@@components.crm.contacts.companyPhone"
      },
      companyAddress: {
        type: "object",
        title: "@@components.crm.contacts.companyAddress",
        "x-schema-form": { inputWidget: WIDGET_FORM_INPUT_GOOGLE_MAPS, asModal: true }
      }
    }
  };
  private readonly BASE_SCHEMA: SchemaFormSchema = {
    type: "object",
    properties: {},
    required: []
  };
  public customFieldSchema: SchemaFormSchema;
  public customFieldData: MetaObject = {};

  protected contact: ContactDto;

  private contactContextVariable$ = toObservable(computed(() => this.getContextReplacedData("contactContextVariable")));

  private contactStore = injectStore({ typePath: TYPE_CONTACTS });

  constructor(
    public fileStore: FileStore,
    private axiosApiClient: AxiosApiClient
  ) {
    super();
    this.subscription.add(
      combineLatest([
        this.contactContextVariable$,
        from(
          this.axiosApiClient
            .get(ContactCustomFieldsApi)
            .getContactCustomFields()
            .then((res) => res.data?.elements)
        )
      ])
        .pipe(
          tap(([contact, customFieldDefinitions]: [ContactDto, ContactCustomFieldDto[]]) => {
            if (contact?.customFields instanceof Array) {
              for (const customField of contact.customFields) {
                const fieldConfig = customFieldDefinitions?.find((ccf) => ccf.id === customField.customFieldId);
                if (customField) {
                  if (fieldConfig?.type === "boolean")
                    this.customFieldData[customField.customFieldId] = customField.value === "true";
                  else if (fieldConfig?.type === "number" || fieldConfig?.type === "date")
                    this.customFieldData[customField.customFieldId] = parseFloat(customField.value);
                  else this.customFieldData[customField.customFieldId] = customField.value;
                }
              }
            }
            this.contact = contact;
            this.cdr.markForCheck();
          })
        )
        .subscribe()
    );

    void this.axiosApiClient
      .get(ContactCustomFieldsApi)
      .getContactCustomFields()
      .then((customFields) => {
        this.customFieldSchema = this.assembleSchema(this.BASE_SCHEMA, customFields?.data?.elements);
        this.cdr.markForCheck();
      });
  }

  public handleSocketData(socketName: string, data?: unknown): Promise<any> {
    if (socketName === "save") {
      this.contact = this.tidyData(this.contact);
      return this.contactStore.createOrUpdate(this.contact);
    }
  }

  public saveContact() {
    return async (): Promise<ContactDto> => {
      this.contact = this.tidyData(this.contact);
      if (this.widgetTreeService.dialogRef) this.widgetTreeService.dialogRef.close(this.contact);
      return this.contactStore.createOrUpdate(this.contact);
    };
  }

  public setAvatar(fileDto: FileDto): void {
    if (fileDto) this.contact.avatarUrl = fileDto.url;
  }

  private tidyData(contact: ContactDto): ContactDto {
    // remove contactCustomFieldValues if it exists
    if ((contact as any).contactCustomFieldValues) delete (contact as any).contactCustomFieldValues;

    contact.customFields = [];

    for (const id in this.customFieldData)
      contact.customFields.push({ customFieldId: id, value: "" + this.customFieldData[id] });
    return contact;
  }

  private assembleSchema(baseSchema: SchemaFormSchema, customFields: ContactCustomFieldDto[]): SchemaFormSchema {
    const schema: SchemaFormSchema = cloneObject(baseSchema);
    if (!customFields?.length) return schema;

    customFields.forEach((customField) => {
      const type: JSONSchema7TypeName =
        customField.type === ContactCustomFieldDtoTypeEnum.Date
          ? "number"
          : customField.type === ContactCustomFieldDtoTypeEnum.Datestring
            ? "string"
            : customField.type;

      schema.properties[customField.id] = { type };

      // display number as date widget
      if (customField.type === ContactCustomFieldDtoTypeEnum.Date)
        schema.properties[customField.id]["x-schema-form"] = { widget: "date" };
      if (customField.type === ContactCustomFieldDtoTypeEnum.Datestring)
        schema.properties[customField.id]["x-schema-form"] = { widget: "datestring" };

      // set a title as string or inlineTranslation
      if (customField.title !== undefined && typeof customField.title === "string")
        schema.properties[customField.id].title = customField.title;
      else {
        if (schema.properties[customField.id]["x-schema-form"])
          schema.properties[customField.id]["x-schema-form"].i18nTitle = customField.title as InlineTranslation;
        else schema.properties[customField.id]["x-schema-form"] = { i18nTitle: customField.title as InlineTranslation };
      }
    });

    return schema;
  }
}
