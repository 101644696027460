import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ThreadMessengerWidget: WidgetRegistryEntry = {
  name: "ThreadMessenger",
  configuration: {
    templateName: "ThreadMessenger",
    templateDescription:
      "Ein Thread Messenger, der es erlaubt, vordefinierte Threads zu starten und Nachrichten zu senden.",
    icon: "fa-comments",
    ssr: false,
    dataSchema: {
      type: "object",
      properties: {
        height: {
          type: "string",
          title: "Höhe",
          description: "Wie hoch soll der Messenger sein?",
          default: "400px",
          "x-schema-form": {
            inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
          }
        }
      }
    }
  },
  component: () => import("./thread-messenger-widget.component").then((m) => m.ThreadMessengerWidgetComponent)
};
