import { WidgetRegistryEntry } from "@smallstack/widget-core";

export const ThreadMessageTemplatesOverviewWidget: WidgetRegistryEntry = {
  name: "ThreadMessageTemplatesOverview",
  configuration: {
    templateName: "Chat Vorlagen Admin Übersicht",
    templateDescription:
      "Zeigt eine Übersicht aller Chats basierend auf Vorlagen an. Diese können dann geöffnet und bearbeitet werden.",
    icon: "fa-comments",
    ssr: false
  },
  component: () =>
    import("./thread-message-templates-overview-widget.component").then(
      (m) => m.ThreadMessageTemplatesOverviewWidgetComponent
    )
};
