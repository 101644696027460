import { APP_INITIALIZER, Provider } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { TYPE_PRICE_ZONES } from "@smallstack/typesystem";
import { WidgetRegistry, WidgetTag } from "@smallstack/widget-core";
import { CartDetailsWidgetComponent } from "./components/widgets/cart-details-widget/cart-details-widget.component";
import { CartIconWidgetComponent } from "./components/widgets/cart-icon-widget/cart-icon-widget.component";
import { ProductListWidgetComponent } from "./components/widgets/product-list-widget/product-list-widget.component";
import { ProductPricesWidgetComponent } from "./components/widgets/product-prices-widget/product-prices-widget.component";
import { PriceZoneStore } from "./stores/price-zone.store";

export function initProductFactory(
  storeRegistry: StoreRegistry,
  priceZoneStore: PriceZoneStore,
  widgetRegistry: WidgetRegistry
) {
  return (): any => {
    storeRegistry.registerStore(TYPE_PRICE_ZONES, priceZoneStore);

    widgetRegistry.addDeprecatedWidget({
      component: CartDetailsWidgetComponent,
      name: "ShoppingCart",
      templateName: "Shopping Cart",
      templateDescription: "Zeigt den Warenkorb des angemeldeten Benutzers an",
      icon: "shopping-cart-loaded",
      tags: [WidgetTag.APPLICATION],
      configuration: async () => CartDetailsWidgetComponent.getConfiguration()
    });
    widgetRegistry.addDeprecatedWidget({
      component: CartIconWidgetComponent,
      name: "ShoppingCartIcon",
      templateName: "Shopping Cart Icon",
      templateDescription: "Zeigt ein Icon an, welches die Anzahl der Waren im Warenkorb anzeigt.",
      icon: "click-and-collect",
      tags: [WidgetTag.APPLICATION],
      configuration: async () => CartIconWidgetComponent.getConfiguration()
    });
    widgetRegistry.addDeprecatedWidget({
      name: "ProductList",
      templateName: "Produkt Liste",
      icon: "product",
      templateDescription:
        "Eine Layout Komponente, welche eine Liste von Produkten in einer Gitteransicht (responsive) darstellt.",
      component: ProductListWidgetComponent,
      configuration: async () => ProductListWidgetComponent.getConfiguration(),
      tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD, WidgetTag.CMS]
    });
    widgetRegistry.addDeprecatedWidget({
      name: "ProductPrices",
      templateName: "Produkt Preise",
      icon: "product",
      templateDescription: "Eine Layout Komponente, welche alle Preise eines bestimmten Produkts auflistet.",
      component: ProductPricesWidgetComponent,
      tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD, WidgetTag.CMS]
    });
  };
}

export const PRODUCT_MODULE_INIT: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [StoreRegistry, PriceZoneStore, WidgetRegistry],
  useFactory: initProductFactory
};
