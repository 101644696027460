<smallstack-store-model [modelId]="productId" [store]="productsStore">
  <ng-template let-product="model">
    @for (price of product.prices; track trackByIndex($index, price)) {
      <div>
        <smallstack-store-model [modelId]="price.priceZoneId" [store]="priceZoneStore">
          <ng-template let-priceZone="model">
            <div class="flex flex-row space-x-1 justify-between items-center">
              <span> {{ priceZone.title }}: </span>
              <span>
                <smallstack-currency [iso]="priceZone.currency" [value]="price.value / 100" />
              </span>
            </div>
          </ng-template>
        </smallstack-store-model>
      </div>
    }
  </ng-template>
</smallstack-store-model>
