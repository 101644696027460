import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, NgZone } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import { TraceService } from "@sentry/angular-ivy";
import { addAllWidgets } from "@smallstack/all-widgets";
import { BackofficeIcons } from "@smallstack/backoffice-shared";
import { ErrorHandlerService, PushNotificationConfiguration } from "@smallstack/client-common";
import {
  ContextService,
  EnvironmentService,
  MultiDialogRootComponent,
  RESELLER_API_CLIENT,
  RouterUtilService,
  ScrollableDirective,
  TRANSLATION_HELPER,
  UrlResolverKeys,
  UrlResolverService
} from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { CRM_INIT_PROVIDER } from "@smallstack/crm-components";
import { LocaleService, TranslationLoader, TranslationStore } from "@smallstack/i18n-components";
import {
  loadApplication,
  loadBackoffice,
  loadBackofficeAuditlog,
  loadCalendar,
  loadCloud,
  loadCommon,
  loadComponents,
  loadCrm,
  loadDashboard,
  loadDevices,
  loadFiles,
  loadForms,
  loadI18n,
  loadInvoice,
  loadMailings,
  loadTodo,
  loadUser,
  loadWidgets
} from "@smallstack/language-packs";
import { LINK_MODULE_INIT } from "@smallstack/link-components";
import { PRODUCT_MODULE_INIT } from "@smallstack/product-components";
import { IconThemeService } from "@smallstack/theme-components";
import { TodoConfigurationService } from "@smallstack/todo-shared";
import { AjvFactory } from "@smallstack/typesystem";
import {
  DraftService,
  TanstackQueryDevToolsComponent,
  TypeService,
  typesystemClientProviders
} from "@smallstack/typesystem-client";
import {
  MsalLoginService,
  MyMessageThreadsStore,
  MyMessagesStore,
  MyPermissionsStore,
  USER_MODULE_INIT,
  UserService
} from "@smallstack/user-components";
import { EXTENSION_SLOT_COMPONENT_PROVIDER, WidgetRegistry } from "@smallstack/widget-core";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { PROJECT_LOCALE_SERVICE } from "./app.constants";
import { SmallstackApiClientModule } from "./modules/api-client/api-client.module";
import { ApiClientFactory } from "./modules/api-client/services/api-client.factory";
import { ContactListComponent } from "./modules/backoffices/crm/contacts/contact-list/contact-list.component";
import { BackofficeDraftService } from "./modules/common/services/backoffice-draft.service";
import { BackofficeTodoConfigurationService } from "./modules/common/services/backoffice-todo-configuration.service";
import { BackofficeUserService } from "./modules/common/services/backoffice-user.service";
import { ResellerContextService } from "./modules/common/services/reseller-context.service";
import { ResellerTranslationStore } from "./modules/common/store/reseller-translation.store";
import { reseller$ } from "./modules/common/store/reseller.store";
import { registerBackofficeWidgets } from "./widgets/register-backoffice-widgets";
import { TODO_MODULE_INIT } from "@smallstack/todo-components";
@NgModule({
  declarations: [AppComponent],
  imports: [
    TanstackQueryDevToolsComponent,
    BrowserAnimationsModule,
    HammerModule,
    ScrollableDirective,
    MatDialogModule,
    RouterModule.forRoot(
      [
        {
          path: "",
          loadChildren: () =>
            import("./modules/backoffices/backoffice.module").then((bo) => bo.SmallstackBackofficeModule)
        }
      ],
      { paramsInheritanceStrategy: "always" }
    ),
    SmallstackApiClientModule,
    // environment.production ? SmallstackServiceWorkerModule : [],
    MultiDialogRootComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (msalLoginService: MsalLoginService) => () => {
        // react to ms logins
        msalLoginService.watchLoginRedirect();
      },
      multi: true,
      deps: [MsalLoginService]
    },
    CRM_INIT_PROVIDER,
    USER_MODULE_INIT,
    PRODUCT_MODULE_INIT,
    LINK_MODULE_INIT,
    ...TODO_MODULE_INIT,
    BackofficeUserService,
    {
      provide: UserService,
      useExisting: BackofficeUserService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (localeService: LocaleService, translationLoader: TranslationLoader) => async () => {
        // register() has to be called before detectLocales()
        translationLoader.register([
          loadApplication,
          loadBackoffice,
          loadCalendar,
          loadCommon,
          loadComponents,
          loadDashboard,
          loadDevices,
          loadFiles,
          loadForms,
          loadI18n,
          loadUser,
          loadCloud,
          loadBackofficeAuditlog,
          loadMailings,
          loadWidgets,
          loadCrm,
          loadInvoice,
          loadTodo
        ]);
        await localeService.getAllLocales();
        await localeService.detectLocale();
      },
      multi: true,
      deps: [LocaleService, TranslationLoader]
    },
    {
      provide: LocaleService,
      useFactory: (apiClientFactory: ApiClientFactory) => {
        return new LocaleService(apiClientFactory.reseller());
      },
      deps: [ApiClientFactory]
    },
    {
      provide: PROJECT_LOCALE_SERVICE,
      useFactory: (apiClientFactory: ApiClientFactory) => {
        return new LocaleService(apiClientFactory.project());
      },
      deps: [ApiClientFactory]
    },
    ResellerTranslationStore,
    {
      provide: TranslationStore, // the default translation store for the backoffice is the one from the reseller, aka the backoffice tenant
      useExisting: ResellerTranslationStore
    },
    {
      provide: ErrorHandler,
      useExisting: ErrorHandlerService
      // useValue: createErrorHandler({
      //   showDialog: false,
      //   logErrors: true
      // })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: TRANSLATION_HELPER,
      useExisting: TranslationStore
    },
    {
      provide: MyPermissionsStore,
      useFactory: (apiClientFactory: ApiClientFactory) => {
        return new MyPermissionsStore(apiClientFactory.reseller());
      },
      deps: [ApiClientFactory]
    },
    {
      provide: RESELLER_API_CLIENT,
      useFactory: (apiClientFactory: ApiClientFactory) => {
        return apiClientFactory.reseller();
      },
      deps: [ApiClientFactory]
    },
    {
      provide: DraftService,
      useClass: BackofficeDraftService
    },
    {
      provide: AjvFactory,
      useFactory: (typeService: TypeService) => {
        return new AjvFactory(typeService);
      },
      deps: [TypeService]
    },
    {
      provide: TodoConfigurationService,
      useClass: BackofficeTodoConfigurationService
    },
    {
      provide: PushNotificationConfiguration,
      useFactory: (contextService: ContextService) => {
        return {
          context: {
            tenantId: () => reseller$.value?.id,
            resellerId: () => reseller$.value?.id,
            authTenantId: () => reseller$.value?.id,
            token: () => contextService.context().token
          }
        };
      },
      deps: [ContextService]
    },
    ...typesystemClientProviders,
    EXTENSION_SLOT_COMPONENT_PROVIDER,
    {
      provide: MyMessageThreadsStore,
      useFactory: (injector: Injector, resellerContextService: ResellerContextService) => {
        return new MyMessageThreadsStore(injector, resellerContextService);
      },
      deps: [Injector, ResellerContextService]
    },
    {
      provide: MyMessagesStore,
      useFactory: (injector: Injector, resellerContextService: ResellerContextService) => {
        return new MyMessagesStore(injector, resellerContextService);
      },
      deps: [Injector, ResellerContextService]
    }
  ]
})
export class AppModule {
  constructor(
    ngZone: NgZone,
    iconThemeService: IconThemeService,
    routerUtilService: RouterUtilService,
    urlResolverService: UrlResolverService,
    contextService: ContextService,
    widgetRegistry: WidgetRegistry,
    environmentService: EnvironmentService
  ) {
    environmentService.set(environment);
    (window as any).ngZone = ngZone;
    iconThemeService.setAliases(BackofficeIcons);

    // register widgets
    addAllWidgets(widgetRegistry);
    registerBackofficeWidgets(widgetRegistry);
    widgetRegistry.registerWidget(ContactListComponent);

    // close dialogs on navigate
    routerUtilService.enableDialogAutoClosing();

    // add important urls to urlResolverService
    urlResolverService.addResolver(UrlResolverKeys.API_URL, async () => environment.apiUrl);
    Logger.info("AppModule", "Backoffice application module loaded...");

    void reseller$.subscribe((reseller) => {
      contextService.updateContext({ resellerId: reseller?.id });
    });
  }
}
