import { AsyncPipe, NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ProductDto } from "@smallstack/axios-api-client";
import { BasePathService, TranslatePipe } from "@smallstack/common-components";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { PageableStore, StoreState } from "@smallstack/store";
import { ListContainerComponent, LoaderComponent } from "@smallstack/store-components";
import { SearchByFieldMatcher, SearchQuery } from "@smallstack/typesystem";
import { BaseWidgetComponent, WidgetTreeComponent } from "@smallstack/widget-core";
import { ProductsStore } from "../../../stores/products.store";

@Component({
  selector: "smallstack-product-list-widget",
  templateUrl: "./product-list-widget.component.html",
  styleUrls: ["./product-list-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LoaderComponent, ListContainerComponent, NgStyle, WidgetTreeComponent, TranslatePipe, AsyncPipe, NgClass]
})
export class ProductListWidgetComponent extends BaseWidgetComponent implements OnInit {
  public filteredProductStore: PageableStore<ProductDto>;

  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        productListElement: {
          title: "Produktlisten-Element",
          type: "object",
          description: "Alle Komponenten, aus denen ein Produktlisten-Element besteht",
          "x-schema-form": {
            widget: "cms"
          },
          default: {
            name: "Container",
            data: {
              children: [
                {
                  name: "Image",
                  data: {
                    width: "100%",
                    alternative: "",
                    url: "${product.imageUrl}",
                    height: "200px",
                    aspectRatio: true,
                    aspectSize: "cover",
                    aspectPosition: "center"
                  }
                },
                {
                  name: "Text",
                  data: {
                    text: "${product.shortTitle}",
                    textSize: "36px",
                    textWeight: "400",
                    padding: "0px 20px",
                    textAlignHorizontal: "start",
                    textAlignVertical: "start",
                    widgetIcon: "edit-property"
                  }
                },
                {
                  name: "Text",
                  data: {
                    text: "${product.shortDescription}",
                    textSize: "18px",
                    textWeight: "400",
                    padding: "0px 20px",
                    textAlignHorizontal: "start",
                    textAlignVertical: "start",
                    widgetIcon: "edit-property"
                  }
                },
                {
                  name: "Container",
                  data: {
                    children: [{ name: "ProductPrices" }],
                    direction: "horizontal",
                    placeContent: "flex-end",
                    placeItems: "flex-end"
                  },
                  id: "c61b1ef4-6f81-412e-b3af-92b01a562264"
                }
              ],
              direction: "vertical",
              placeContent: "flex-start",
              placeItems: "stretch",
              padding: "0px 0px 100px 0px"
            }
          }
        } as SchemaFormSchema,
        className: {
          type: "string",
          title: "CSS-Klasse",
          description: "Vergeben Sie einen Klassennamen zum Anwenden von benutzerdefinierten Styles.",
          default: "productlist-item"
        },
        tags: {
          title: "Produkt-Tags",
          description: "Zum Filtern der Produkte anhand von tags oder Kategorien.",
          type: "array",
          items: {
            type: "string"
          }
        },
        showProductsCount: {
          title: "Anzahl Produkte",
          description: "Wie viele Produkte sollen in der Liste angezeigt werden?",
          type: "string",
          enum: ["1", "2", "3", "4", "6", "8", "9", "12", "15", "16", "20", "24"],
          default: "6"
        },
        showPaginator: {
          title: "Pager anzeigen",
          description: "Soll die Liste seitenweises Blättern zulassen",
          type: "string",
          enum: ["never", "always", "auto"],
          default: "auto"
        },
        link: {
          type: "string",
          title: "Verlinkung",
          description:
            "Falls gesetzt, dann leitet ein Mausklick auf die angegebene Seite weiter. Verwenden Sie den Platzhalter ${id} für die ID des aktuellen Elementes."
        }
      }
    };
  }

  constructor(
    public productsStore: ProductsStore,
    private basePathService: BasePathService,
    private router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.filterProductsByTags();
  }

  public filterProductsByTags(): void {
    let search: SearchQuery;
    if (this.data()?.tags?.length > 0)
      search = {
        fieldSearches: [{ fieldname: "tags", value: this.data()?.tags, matcher: SearchByFieldMatcher.IN }],
        logicalOperator: "or"
      };
    else search = { fieldSearches: [], logicalOperator: "or" };
    this.filteredProductStore = this.productsStore?.createSearch(search);
    if (this.filteredProductStore.state !== StoreState.LOADED) {
      this.filteredProductStore.setPageSize(this.data()?.showProductsCount);
      void this.filteredProductStore.load();
    }
  }

  public async navigateTo(elem: any): Promise<void> {
    if (this.data().link) {
      await this.router.navigateByUrl(
        "/" + this.basePathService.basePath$.value + this.data().link.replace("${id}", elem.id)
      );
    }
  }
}
