import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { PriceZoneDto, ShoppingCartDto, ShoppingCartPositionDto } from "@smallstack/axios-api-client";
import { BasePathService, LoadingElementDirective } from "@smallstack/common-components";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { CurrencyComponent, I18nComponent } from "@smallstack/i18n-components";
import { StoreContainerComponent, StoreModelComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { UserService } from "@smallstack/user-components";
import { BaseWidgetComponent } from "@smallstack/widget-core";
import { PriceZoneStore } from "../../../stores/price-zone.store";
import { ProductsStore } from "../../../stores/products.store";
import { ShoppingCartStore } from "../../../stores/shopping-cart.store";

@Component({
  selector: "smallstack-cart-details-widget",
  templateUrl: "./cart-details-widget.component.html",
  styleUrls: ["./cart-details-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    StoreContainerComponent,
    StoreModelComponent,
    I18nComponent,
    IconComponent,
    LoadingElementDirective,
    CurrencyComponent
  ]
})
export class CartDetailsWidgetComponent extends BaseWidgetComponent {
  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        orderLink: {
          type: "string",
          description:
            "Setzen Sie hier die Route, welche beim Klick auf 'Jetzt bestellen' aufgerufen wird. Falls nicht gesetzt, wird der Button ausgeblendet."
        }
      }
    };
  }

  protected currentUserId$ = this.userService.currentUserId$;

  constructor(
    public shoppingCartStore: ShoppingCartStore,
    private userService: UserService,
    public productsStore: ProductsStore,
    public priceZoneStore: PriceZoneStore,
    private router: Router,
    private basePathService: BasePathService
  ) {
    super();
  }

  public addProduct(productId: string) {
    return async (): Promise<void> => {
      await this.shoppingCartStore.addItem(productId);
    };
  }

  public removeProduct(productId: string) {
    return async (): Promise<void> => {
      await this.shoppingCartStore.removeItem(productId);
    };
  }

  public removePosition(position: number) {
    return async (): Promise<void> => {
      await this.shoppingCartStore.removePosition(position + 1);
    };
  }

  public orderNow(): Promise<boolean> {
    if (this.data()?.orderLink)
      return this.router.navigateByUrl(this.basePathService.addBasePath(this.data().orderLink));
  }

  public trackByModel(index: number, model: ShoppingCartPositionDto | ShoppingCartDto | PriceZoneDto): string {
    return JSON.stringify(model);
  }

  public trackByIndex(index: number, model: ShoppingCartPositionDto | ShoppingCartDto | PriceZoneDto): number {
    return index;
  }
}
