import { Injectable } from "@angular/core";
import {
  AxiosApiClient,
  OrderDto,
  PlaceOrderDto,
  PriceZoneDto,
  ShoppingCartDto,
  ShoppingCartPositionDto,
  ShoppingCartsApi
} from "@smallstack/axios-api-client";
import { Store } from "@smallstack/store";
import { UserService } from "@smallstack/user-components";
import { filterNullish } from "@smallstack/legacy-utils";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { PriceZoneStore } from "./price-zone.store";
import { ProductsStore } from "./products.store";

export interface ShoppingCartTotalPrice {
  value: number;
  priceZone: PriceZoneDto;
}

/**
 * Loads and holds the current shopping card if injected into any component. Otherwise, nothing will happen!
 */
@Injectable({ providedIn: "root" })
export class ShoppingCartStore extends Store<ShoppingCartDto> {
  // TODO: This could be a really cool RxJS challenge
  public totalPrices$: Observable<ShoppingCartTotalPrice[]> = this.value$.pipe(
    filterNullish(),
    map((shoppingCart) => {
      if (!(shoppingCart?.orderPositions instanceof Array) || shoppingCart.orderPositions.length === 0)
        return undefined;
      return shoppingCart.orderPositions;
    }),
    filterNullish(),
    switchMap(async (orderPositions: ShoppingCartPositionDto[]) => {
      const prices: ShoppingCartTotalPrice[] = [];
      for (const orderPosition of orderPositions) {
        const product = await this.productsStore.get(orderPosition.productId);
        if (!product) continue;
        for (const price of product.prices) {
          const priceZone = await this.priceZoneStore.get(price.priceZoneId);
          if (!priceZone) continue;
          const existingPriceIndex: number = prices.findIndex((sctp) => sctp.priceZone.id === priceZone.id);
          if (existingPriceIndex !== -1) prices[existingPriceIndex].value += price.value * orderPosition.amount;
          else prices.push({ priceZone, value: price.value * orderPosition.amount });
        }
      }
      return prices;
    })
  );

  constructor(
    private axiosApiClient: AxiosApiClient,
    userService: UserService,
    private productsStore: ProductsStore,
    private priceZoneStore: PriceZoneStore
  ) {
    super();
    void userService.currentUserId$.subscribe((userId) => {
      if (userId) return this.loadShoppingCard();
      return this.reset();
    });
  }

  /**
   * Adds products to the shopping cart
   *
   * @param productId The productId to be added to the shopping cart
   * @param amount The positive amount of products, defaults to 1
   * @returns The updated shopping cart
   */
  public async addItem(productId: string, amount: number = 1): Promise<ShoppingCartDto> {
    if (amount > 0) {
      const shoppingCart = (
        await this.axiosApiClient.get(ShoppingCartsApi).addProductToMyShoppingCart({ amount, productId })
      ).data;
      this.setValue(shoppingCart);
      return shoppingCart;
    }
  }

  /**
   * Removes items from the shopping cart
   *
   * @param productId The ProductId to be removed
   * @param amount The amount of products that should be removed, defaults to 1
   * @returns The updated shopping cart
   */
  public async removeItem(productId: string, amount: number = 1): Promise<ShoppingCartDto> {
    if (amount > 0) {
      const shoppingCart = (
        await this.axiosApiClient.get(ShoppingCartsApi).removeProductFromMyShoppingCart({ amount, productId })
      ).data;
      this.setValue(shoppingCart);
      return shoppingCart;
    }
  }

  /**
   * Removes a complete position from the shopping cart
   *
   * @param position The position as positive, starting with the number 1
   * @returns The updated shopping cart
   */
  public async removePosition(position: number): Promise<ShoppingCartDto> {
    if (position > 0) {
      const shoppingCart = (
        await this.axiosApiClient.get(ShoppingCartsApi).removePositionFromMyShoppingCart({ position })
      ).data;
      this.setValue(shoppingCart);
      return shoppingCart;
    }
  }

  /**
   *  Orders the current shopping cart
   *
   * @param placeOrder Please provide the delivery- and billingAddress
   * @returns If shopping cart consists of items from different suppliers, one order per supplier will be created
   */
  public async orderNow(placeOrder: PlaceOrderDto): Promise<OrderDto[]> {
    const orders: OrderDto[] = (await this.axiosApiClient.get(ShoppingCartsApi).placeOrder({ placeOrder })).data;
    await this.loadShoppingCard();
    return orders;
  }

  public async loadShoppingCard(): Promise<ShoppingCartDto> {
    const shoppingCart = (await this.axiosApiClient.get(ShoppingCartsApi).getMyShoppingCart()).data;
    this.setValue(shoppingCart);
    return shoppingCart;
  }
}
