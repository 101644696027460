import { APP_INITIALIZER, Provider } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { TYPE_CONTACTS, TYPE_CORPORATIONS } from "@smallstack/typesystem";
import { SchemaFormInputsRegistry, WidgetRegistry } from "@smallstack/widget-core";
import { ContactEditorWidgetComponent } from "./components/widgets/contact-editor-widget/contact-editor-widget.component";
import { ContactPersonFormInputComponent } from "./components/widgets/contact-person-form-input/contact-person-form-input.component";
import { CustomContactFieldsInputWidgetComponent } from "./components/widgets/custom-contact-fields-input-widget/custom-contact-fields-input-widget.component";
import { ContactsStore } from "./stores/contacts.store";
import { CorporationStore } from "./stores/corporations.store";

export function provideCrmFunctionality(
  schemaFormInputsRegistry: SchemaFormInputsRegistry,
  storeRegistry: StoreRegistry,
  contactsStore: ContactsStore,
  corporationStore: CorporationStore,
  widgetRegistry: WidgetRegistry
) {
  return (): any => {
    // register stores
    storeRegistry.registerStore(TYPE_CONTACTS, contactsStore);
    storeRegistry.registerStore(TYPE_CORPORATIONS, corporationStore);

    // register input widgets
    schemaFormInputsRegistry.addWidget("CustomContactFields", CustomContactFieldsInputWidgetComponent);
    schemaFormInputsRegistry.addWidget("contactperson", ContactPersonFormInputComponent);

    // register widgets
    widgetRegistry.registerWidget(ContactEditorWidgetComponent);
  };
}

export const CRM_INIT_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [SchemaFormInputsRegistry, StoreRegistry, ContactsStore, CorporationStore, WidgetRegistry],
  useFactory: provideCrmFunctionality
};
