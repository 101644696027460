import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "UserGroupsList",
  templateName: "Benutzergruppenliste",
  templateDescription:
    "Zeigt alle Benutzergruppen in einer Liste an und bietet Möglichkeiten, neue Benutzergruppen anzulegen und bestehende zu bearbeiten.",
  icon: "groups"
})
@Component({
  selector: "smallstack-user-user-groups-list-widget",
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: "./user-groups-list-widget.component.html",
  styleUrls: ["./user-groups-list-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupsListWidgetComponent extends BaseWidgetComponent {}
