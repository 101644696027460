import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { ContactCustomFieldValueDto } from "@smallstack/axios-api-client";
import { DatestringEditorComponent } from "@smallstack/form-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { StoreContainerComponent, StoreModelComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { first } from "rxjs";
import { ContactCustomFieldStore } from "../../../stores/contact-custom-field.store";

@Component({
  selector: "smallstack-custom-contact-fields-input-widget",
  templateUrl: "./custom-contact-fields-input-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    StoreContainerComponent,
    MatLabel,
    MatFormField,
    MatSelect,
    FormsModule,
    MatOption,
    I18nComponent,
    StoreModelComponent,
    MatCheckbox,
    MatInput,
    DatestringEditorComponent,
    IconComponent,
    AsyncPipe
  ]
})
export class CustomContactFieldsInputWidgetComponent extends SchemaFormBaseWidget implements OnInit {
  protected customContactFieldValues: ContactCustomFieldValueDto[];

  constructor(protected contactCustomFieldStore: ContactCustomFieldStore) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.value$.pipe(first()).subscribe((value) => (this.customContactFieldValues = value)));
  }

  protected addEntry(): void {
    if (this.customContactFieldValues === undefined) this.customContactFieldValues = [];
    this.customContactFieldValues.push({} as any);
  }

  protected removeEntry(index: number): void {
    if (this.customContactFieldValues === undefined) return;
    this.customContactFieldValues.splice(index, 1);
    this.updateValue();
  }

  protected updateValue(): void {
    if (!this.customContactFieldValues || this.customContactFieldValues.length === 0) this.setValue(undefined);
    else this.setValue(this.customContactFieldValues);
  }
}
