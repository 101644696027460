import { SchemaFormSchema } from "@smallstack/form-shared";
import { BooleanEquationOperator } from "@smallstack/legacy-utils";
import { AllWidgetTags, WidgetRegistryEntry } from "@smallstack/widget-core";

export const TableWidget: WidgetRegistryEntry = {
  name: "Table",
  configuration: {
    templateName: "Widget Tabelle",
    templateDescription: "Zeigt eine einfach Tabelle an, welche mit Widgets gefüllt werden kann.",
    icon: "grid",
    dataSchema: {
      type: "object",
      properties: {
        rows: {
          title: "Anzahl Zeilen",
          description: "Geben Sie hier die Anzahl der Zeilen an.",
          type: "number",
          default: 2
        },
        cols: {
          title: "Anzahl Spalten",
          description: "Geben Sie hier die Anzahl der Spalten pro Zeile an.",
          type: "number",
          default: 2
        },
        tableSpacing: {
          title: "Tabellenabstände außen",
          description:
            "Geben Sie hier an, wie die Abstände um die einzelnen Zellen in der Tabelle sein sollen. Angaben in px.",
          type: "string",
          default: "0px 0px"
        },
        tablePadding: {
          title: "Tabellenabstände innen",
          description:
            "Geben Sie hier an, wie die Abstände innerhalb der einzelnen Tabellen-Zellen sein sollen. Angaben in px.",
          type: "string",
          default: "4px 8px"
        },
        tableBorder: {
          title: "Tabellenrahmenoptionen",
          description: "Geben Sie hier an, ob die Tabelle einen Rahmen haben soll.",
          type: "boolean",
          default: false
        },
        tdVerticalAlign: {
          title: "Vertikale Zellenausrichtung",
          description: "Wie soll der Inhalt in der Zelle vertikal ausgerichtet sein?",
          enum: ["top", "middle", "bottom"],
          default: "middle"
        },
        tableBorderStyle: {
          title: "Tabellenrahmenstil",
          description: "Geben Sie hier an, welchen Rahmenstil die Tabelle haben soll.",
          type: "string",
          enum: ["none", "solid", "dashed", "dotted"],
          default: "solid",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../tableBorder",
                  operator: BooleanEquationOperator.NOT_EQUALS,
                  value: true
                }
              }
            ]
          }
        },
        tableBorderWidth: {
          title: "Tabellenrahmendicke",
          description: "Geben Sie hier an, wie dick der Rahmen der Tabelle sein soll.",
          type: "string",
          default: "2px",
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../tableBorder",
                  operator: BooleanEquationOperator.NOT_EQUALS,
                  value: true
                }
              }
            ]
          }
        },
        tableBorderColor: {
          title: "Tabellenrahmenfarbe",
          description: "Geben Sie hier an, welche Farbe der Rahmen der Tabelle haben soll.",
          type: "object",
          "x-schema-form": {
            widget: "color",
            "x-schema-form": {
              rules: [
                {
                  action: "hide",
                  if: {
                    dataPath: "../tableBorder",
                    operator: BooleanEquationOperator.NOT_EQUALS,
                    value: true
                  }
                }
              ]
            }
          }
        } as SchemaFormSchema,
        tableBorderCollapse: {
          title: "Doppelte Tabellenrahmen",
          description: "Geben Sie hier an, ob jede Tabellenzelle einen eigenen Rahmen haben soll.",
          type: "boolean",
          default: false,
          "x-schema-form": {
            rules: [
              {
                action: "hide",
                if: {
                  dataPath: "../tableBorder",
                  operator: BooleanEquationOperator.NOT_EQUALS,
                  value: true
                }
              }
            ]
          }
        }
      }
    },
    tags: AllWidgetTags
  },
  component: () => import("./table-widget.component").then((tw) => tw.TableWidgetComponent)
};
